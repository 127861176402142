import $ from "jquery";
import "parsleyjs";
export class Forms {
  forms = null;
  constructor($selector = $('form')) {
    if ($selector.length > 0) {
      this.forms = $selector;
      this.addValidators();
    }
  }

  validate() {
    if (this.forms) {
      this.forms.parsley({
        inputs: Parsley.options.inputs + ',[data-parsley-recaptcha],[data-parsley-required-field],[data-parsley-check-children],[data-parsley-geocode]',
        excluded: Parsley.options.excluded + ', input:hidden',
        errorClass: 'is-invalid',
        errorsWrapper: '<div class="invalid-feedback d-inline-block line-height-sm"></div>',
        errorTemplate: '<span></span>'
      })
    }
  }

  addValidators() {
    Parsley.addValidator('requiredField', {
      requirementType: 'string',
      validate: function (_value, requirement, instance) {

        var $element = $(instance.$element);

        var input = $element.find('[name="' + requirement + '"]:checked, [name="' + requirement + '"]');
        var inputVal = '';
        var isValid = false;

        if (input.is(':radio')) {
          isValid = input.filter(function () { return $(this).is(':checked'); }).length > 0;
        } else {
          inputVal = input.val();
          isValid = !!$.trim(inputVal);
        }

        console.log(requirement, 'isValid', isValid, 'Elements exists', input.length > 0);

        return isValid; // No section is filled, this validation fails
      }
    });
  }

  steps() {
    var $sections = $('.form-section');

    function navigateTo(index) {
      // Mark the current section with the class 'current'
      $sections
        .removeClass('d-block')
        .addClass('d-none')
        .eq(index)
        .addClass('d-block');
      // Show only the navigation buttons that make sense for the current section:
      $('.form-navigation .previous').toggle(index > 0);
      var atTheEnd = index >= $sections.length - 1;
      $('.form-navigation .next').toggle(!atTheEnd);
      $('.form-navigation [type=submit]').toggle(atTheEnd);
    }

    function curIndex() {
      // Return the current index by looking at which section has the class 'current'
      return $sections.index($sections.filter('.d-block'));
    }

    // Previous button is easy, just go back
    $('.form-navigation .previous').click(function () {
      navigateTo(curIndex() - 1);
    });

    // Next button goes forward iff current block validates
    $('.form-navigation .next').click(function () {
      let form = $(this).closest('form');
      form.parsley({
        inputs: Parsley.options.inputs + ',[data-parsley-recaptcha],[data-parsley-required-field],[data-parsley-check-children],[data-parsley-geocode]',
        excluded: Parsley.options.excluded + ', input:hidden',
        errorClass: 'is-invalid',
        errorsWrapper: '<div class="invalid-feedback d-inline-block line-height-sm"></div>',
        errorTemplate: '<span></span>'
      }).whenValidate({
        group: 'block-' + curIndex()
      }).done(function () {
        navigateTo(curIndex() + 1);
      });
    });

    // Prepare sections by setting the `data-parsley-group` attribute to 'block-0', 'block-1', etc.
    $sections.each(function (index, section) {
      $(section).find('.input').attr('data-parsley-group', 'block-' + index);
    });
    navigateTo(0); // Start at the beginning
  }


}